<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <public-filter
          :action-methods="searchData"
          :action-filter="filterData"
          :reset-filter="resetSearch"
        />
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Stok Listesi</b-card-title>
          <b-button
            variant="primary"
            to="/stocks/add"
          >
            Stok Ekle
          </b-button>
        </b-card-header>
        <b-table
          class="font-small-3"
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          hover
        >
          <template #cell(brand)="data">
            <div>{{ data.item.brand }} {{ data.item.model }}</div>
            <div class="font-small-2 text-warning">
              Renk: {{ data.item.color }} - Model Yılı: {{ data.item.myear }}
            </div>
            <div class="text-muted font-small-2">
              {{ data.item.hardware }}
            </div>
            <div class="text-info font-small-2">
              {{ data.item.username }}
            </div>
          </template>
          <template #cell(stock_status)="data">
            <div v-if="data.item.stock_status">
              {{ data.item.stock_status }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(order_number)="data">
            <div v-if="data.item.id_com_sale_orderform">
              <b-link
                :href="'/sale_orderforms/view/' + data.item.order_number"
                target="_blank"
                variant="danger"
              >
                {{ data.item.order_number }}
              </b-link>
              <div class="text-danger font-small-2 font-weight-bold">
                {{ data.item.sale_username }}
              </div>
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(control)="data">
            <div>
              <b-button
                variant="primary"
                size="sm"
                :to="'/stocks/edit/' + data.item.id"
                block
              >
                <FeatherIcon icon="EditIcon" />
                Güncelle
              </b-button>
            </div>
            <div
              v-if="!data.item.id_com_sale_orderform"
              class="mt-1"
            >
              <b-button
                variant="outline-danger"
                size="sm"
                block
                @click="removeData(data.item.id)"
              >
                <FeatherIcon icon="TrashIcon" />
                Sil
              </b-button>
            </div>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BCardHeader, BCardTitle, BLink,
} from 'bootstrap-vue'
import PublicFilter from '@/views/Sales/Stocks/Index/PublicFilter.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BCardHeader,
    BCardTitle,
    BLink,
    PublicFilter,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'brand',
          label: 'Araç Bilgisi',
        },
        {
          key: 'chasis',
          label: 'ŞASE NO',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'stock_status',
          label: 'STOK DURUM',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'order_number',
          label: 'SATIŞ DURUM',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          'com_stock.id AS id',
          'com_brand.name AS brand',
          'com_model.title AS model',
          'com_color.title AS color',
          'com_stock.myear AS myear',
          'com_stock.chasis AS chasis',
          'com_stock.hardware AS hardware',
          'com_stock_statuses.title AS stock_status',
          'com_user.name AS username',
          'sale_user.name AS sale_username',
          'com_sale_orderform.id AS id_com_sale_orderform',
          'com_sale_orderform.order_number AS order_number',
        ],
        order_by: ['com_stock.id', 'DESC'],
        where: {
          '_mssql_aracsatis.id': null,
        },
        limit: this.$store.state.app.perPage,
        start: 0,
        joinSales: true,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['stock/getStocks']
    },
    dataCount() {
      return this.$store.getters['stock/getStocksCount']
    },
    quickSearch() {
      return this.$store.getters['stock/getQuickSearch']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * this.$store.state.app.perPage
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      if (!this.dataQuery.where['com_stock.id_com_brand']) {
        if (['1', '2', '3', '4', '5', '6', '13'].includes(this.userData.id_com_brand)) {
          this.dataQuery.where['com_stock.id_com_brand'] = this.userData.id_com_brand
        }
      }
      this.$store.dispatch('stock/stocks', this.dataQuery)
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    searchData() {
      if (this.quickSearch.keyword.length > 0) {
        this.dataQuery.like = {
          'com_stock.chasis': this.quickSearch.keyword,
        }
        this.getDataList()
      }
    },
    resetSearch() {
      this.dataQuery.like = {}
      this.dataQuery.where = {
        '_mssql_aracsatis.id': null,
      }
      this.getDataList()
    },
    filterData(params) {
      const where = {
        '_mssql_aracsatis.id': null,
      }
      if (params.id_com_brand) {
        where['com_stock.id_com_brand'] = params.id_com_brand
      }
      if (params.id_com_model) {
        where['com_stock.id_com_model'] = params.id_com_model
      }
      if (params.id_com_stock_statuses) {
        where['com_stock.id_com_stock_statuses'] = params.id_com_stock_statuses
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('stock/stockDelete', id)
            .then(response => {
              if (response.status) {
                this.getDataList()
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
