<template>
  <div>
    <b-row class="d-flex align-items-center">
      <b-col
        cols="12"
      >
        <b-form-group
          label="Hızlı Arama"
          label-for="keyword"
        >
          <b-input-group>
            <b-form-input
              id="keyword"
              v-model="quickSearch.keyword"
              placeholder="Şase No"
              @keydown.enter="searchAction"
            />
            <b-input-group-append>
              <b-button
                v-if="quickSearch.keyword"
                variant="warning"
                @click="resetAction"
              >
                <FeatherIcon icon="XCircleIcon" />
              </b-button>
              <b-button
                variant="primary"
                @click="searchAction"
              >
                <FeatherIcon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Marka"
          label-for="id_com_brand"
        >
          <v-select
            id="id_com_brand"
            v-model="filterData.id_com_brand"
            placeholder="Marka"
            :options="brands"
            label="title"
            :reduce="item => item.id"
            @input="getModels"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Model"
          label-for="id_com_model"
        >
          <v-select
            id="id_com_model"
            v-model="filterData.id_com_model"
            placeholder="Model"
            :options="models"
            label="title"
            :reduce="item => item.id"
            :disabled="models.length === 0"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Stok Durumu"
          label-for="id_com_stock_statuses"
        >
          <v-select
            id="id_com_stock_statuses"
            v-model="filterData.id_com_stock_statuses"
            placeholder="Stok Durumu"
            :options="stockStatuses"
            label="title"
            :reduce="item => item.id"
            :disabled="stockStatuses.length === 0"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <b-button
          variant="primary"
          style="margin-top: 4px"
          @click="filterAction"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'PublicFilter',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },
  props: {
    actionMethods: {
      type: Function,
      required: true,
    },
    actionFilter: {
      type: Function,
      required: true,
    },
    resetFilter: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      keyword: null,
      detailSearch: false,
      filterData: {
        id_com_brand: null,
        id_com_model: null,
        id_com_stock_statuses: null,
      },
    }
  },
  computed: {
    quickSearch() {
      return this.$store.getters['stock/getQuickSearch']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    models() {
      return this.$store.getters['models/getModels']
    },
    stockStatuses() {
      return this.$store.getters['stockStatuses/getDataList']
    },
  },
  created() {
    this.getBrands()
    this.getStockStatuses()
    this.$store.commit('models/MODELS_LIST', [])
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    getModels() {
      this.filterData.id_com_model = null
      this.$store.commit('models/MODELS_LIST', [])
      this.$store.dispatch('models/modelsList', {
        select: ['com_model.id AS id', 'com_model.title AS title'],
        where: {
          'com_model.id_com_brand': this.filterData.id_com_brand,
        },
      })
    },
    getStockStatuses() {
      this.$store.dispatch('stockStatuses/dataList', {
        select: ['com_stock_statuses.id AS id', 'com_stock_statuses.title AS title'],
      })
    },
    searchAction() {
      this.actionMethods()
    },
    filterAction() {
      this.actionFilter(this.filterData)
    },
    resetAction() {
      this.filterData = {
        id_com_brand: null,
        id_com_model: null,
        id_com_stock_statuses: null,
      }
      this.quickSearch.keyword = null
      this.resetFilter()
    },
  },
}
</script>
